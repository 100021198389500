:root {
  --main-text-color: #1f1f1f;
  --main-secondary-color: #a3a3a3;
  --main-input-label-color: #4d4d4d;
  --main-background-color: #ffffff;
  --main-accent-color: #d91e40;
  --main-accent-text-color: #ffffff;
  --alternate-text-color: #ffffff;
  --alternate-background-color: #1f1f1f;
  --contact-links-color: #0563c1;
  --top-bar-color: #e9e9e9;
}

$breakpoints: (
  xs: 0px,
  sm: 768px,
  md: 1120px,
  lg: 1480px
);

$max-page-width-desktop: 592px;
$max-page-width-mobile: 320px;

$font-lato: 'Lato';
$font-playfair: 'Playfair Display';

$font-size-app-title: 48px;
$font-size-page-title: 48px;
$font-size-page-title-benefits: 60px;
$font-size-p: 20px;
$font-size-p-benefits: 18px;
$font-size-p-li: 16px;
$font-size-p-sub: 12px;
$font-size-p-sub-mini: 10px;
$font-size-p-tips: 16px;
$font-size-result-main: 16px;
$font-size-p-our-company: 14px;
$font-size-input-label: 10px;
$font-size-input: 21px;
$font-size-button: 24px;
$font-size-submit-button: 20px;
$font-size-result-submit-button: 16px;
$font-size-tips-submit-button: 14px;
$font-size-main-powered-by: 16px;
$font-size-footer-powered-by: 16px;
$font-size-company-name: 22px;
$font-size-tiny: 10px;
$font-header: 33px;
$font-big-score: 40px;
$font-circle-score: 22px;
$font-result-content: 18px;
$font-result-sub: 12px;
$font-loading-text: 24px;
$font-top-bar: 16px;

$font-size-mobile-app-title: 30px;
$font-size-mobile-page-title: 36px;
$font-size-mobile-page-title-benefits: 32px;
$font-size-mobile-p: 14px;
$font-size-mobile-p-small: 12px;
$font-size-mobile-p-sub: 9px;
$font-size-mobile-p-sub-start: 11px;
$font-size-mobile-p-terms: 9px;
$font-size-mobile-input-label: 10px;
$font-size-mobile-input: 21px;
$font-size-mobile-button: 24px;
$font-circle-score-mobile: 16px;
$font-size-mobile-submit-button: 16px;
$font-size-mobile-main-powered-by: 12px;
$font-size-mobile-footer-powered-by: 10px;
$font-size-mobile-company-name: 16px;
$font-size-mobile-tips-header: 16px;
$font-size-mobile-feedback-header: 16px;
$font-mobile-header: 24px;
$font-mobile-big-score: 26px;
$font-mobile-percent-score: 24px;
$font-mobile-result-content: 12px;
$font-mobile-result-sub: 11px;
$font-mobile-tips-sub: 11px;
$font-mobile-loading-text: 18px;
$font-mobile-tiny: 9px;
$font-mobile-contacts: 18px;
$font-mobile-top-bar: 10px;

$color-main-text: var(--main-text-color);
$color-main-secondary: var(--main-secondary-color);
$color-main-input-label: var(--main-input-label-color);
$color-main-background: var(--main-background-color);
$color-main-accent: var(--main-accent-color);
$color-main-accent-text: var(--main-accent-text-color);
$color-alternate-text: var(--alternate-text-color);
$color-alternate-background: var(--alternate-background-color);
$contact-links-color: var(--contact-links-color);
$color-top-bar: var(--top-bar-color);

$fill-main: var(--main-text-color);
$fill-alternate: var(--alternate-text-color);
$fill-secondary: var(--main-secondary-color);
$fill-accent: var(--main-accent-color);
