@import '~assets/styles/common';

.App {
  max-width: $max-page-width-desktop;
  margin: auto;
  padding: 0px 21px;
  margin-top: 40px;

  @include device(xs) {
    max-width: $max-page-width-mobile;
    margin-top: 26px;
  }
}

body {
  background: var(--main-background-color);
  color: var(--main-text-color);
}

body.alternate {
  background: var(--alternate-background-color);
  color: var(--alternate-text-color);
}

.fade-animation {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
