@import '~assets/styles/common';

.powered-by-container {
  margin-bottom: 32px;

  @include device(xs) {
    margin-bottom: 16px;
    margin-left: 8px;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  a,
  a:hover {
    text-decoration: none;
  }

  span {
    color: $color-main-text;
    font-family: $font-lato;
    font-size: $font-size-main-powered-by;
    font-weight: 300;
    padding: 3px 9px;
    position: relative;
    bottom: 7px;

    @include device(xs) {
      padding: 1px 3px;
      font-size: $font-size-mobile-main-powered-by;
      bottom: 3px;
    }
  }

  svg {
    width: 87px;
    height: 27px;
    fill: $fill-main;

    @include device(xs) {
      width: 58px;
      height: 18px;
    }
  }
}

.footer-powered-by {
  margin: 70px auto;

  @include device(xs) {
    margin: 20px auto 70px auto;
  }

  span {
    font-size: $font-size-footer-powered-by;

    @include device(xs) {
      font-size: $font-size-mobile-footer-powered-by;
    }
  }

  svg {
    width: 87px;
    height: 27px;
    fill: $fill-main;

    @include device(xs) {
      width: 45px;
      height: 14px;
    }
  }
}

.contact-powered-by {
  margin: 70px auto;

  @include device(xs) {
    margin: 20px auto 70px auto;
  }

  span {
    font-size: $font-size-footer-powered-by;
    color: $color-alternate-text;

    @include device(xs) {
      font-size: $font-size-mobile-footer-powered-by;
    }
  }

  svg {
    width: 87px;
    height: 27px;
    fill: $fill-alternate;

    @include device(xs) {
      width: 45px;
      height: 14px;
    }
  }
}

.feedback-powered-by {
  margin: 0px auto 45px auto;

  span {
    font-size: $font-size-mobile-footer-powered-by;
    bottom: 3px;
  }

  svg {
    fill: $fill-main;
    width: 45px;
    height: 14px;
  }
}
