@import "~assets/styles/common";

.submit-button-container {
  button {
    color: $color-main-accent-text;
    font-family: $font-lato;
    font-size: $font-size-button;
    font-weight: 900;
    width: 100%;
    height: 66px;
    background-color: $color-main-accent;
    border: none;
  }

  button:hover,
  button:active,
  button:focus {
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.result-submit {
    margin-top: 30px;
    margin-bottom: 9px;

  .submit-button-container {
    display: flex;

    button {
      font-size: $font-size-result-submit-button;
      height: 45px;
      width: 175px;
      margin: auto;

      @include device(xs) {
        font-size: $font-size-mobile-submit-button;
        height: 44px;
        width: 218px;
      }
    }
  }
}

.info-submit {
  margin: 20px auto 23px auto;
  text-align: center;

  @include device(xs) {
    margin-top: 23px;
    margin-bottom: 9px;
  }

  .submit-button-container {
    button {
      font-size: $font-size-result-submit-button;
      height: 45px;
      width: 175px;

      @include device(xs) {
        font-size: $font-size-mobile-submit-button;
        height: 44px;
      }
    }
  }
}
