@import "~assets/styles/common";

.page-bar-container {
  z-index: 10;

  .top-bar-container {
    font-family: $font-lato;
    font-size: $font-top-bar;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $color-top-bar;
    padding: 11px 0;

    span {
      margin: 0 8px;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    @include device(xs) {
      font-size: $font-mobile-top-bar;
      padding: 8px 0 4px 0;

      span {
        margin: 0 4px;
      }

      svg {
        height: 14px;
        width: 14px;
      }
    }
  }

  .feedback-drawer-container {
    z-index: 10;

    #drawer-toggle-container {
      display: block;
      position: fixed;
      height: 100vh;
      top: 0px;
      right: 0px;
      width: 300px;
      min-height: 100vh;
      transform-origin: 0% 0%;
      transform: translate(+100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

      .toggle {
        position: fixed;
        top: 80%;
        right: 281px;
        min-height: 21px;
        font-size: $font-size-p-sub;
        font-weight: bold;
        font-family: $font-playfair;
        border: 1px solid $color-main-secondary;
        border-bottom: 0;
        padding: 8px 14px;
        transform: rotate(-90deg);
        cursor: pointer;
        color: $color-alternate-text;
        background-color: $fill-accent;

        @include device(xs) {
          top: 72%;
        }
      }

      #drawer {
        border-left: 1px solid $color-main-secondary;
        background-color: $fill-alternate;

        .feedback-form-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 100vh;
          font-weight: 100;

          .page-header {
            margin-top: 33px;
            margin-bottom: 40px;
            text-align: center;
            font-size: $font-size-mobile-feedback-header;
          }

          .main-form {
            padding: 21px 21px 10px 21px;

            .back-img {
              cursor: pointer;

              svg {
                position: absolute;
                top: 23px;
                left: 23px;
                height: 14px;
                width: 18px;

                path {
                  fill: $fill-main;
                }
              }

              span {
                position: absolute;
                top: 23px;
                left: 43px;
                font-size: $font-size-input-label;
              }
            }
          }

          .submit-button-container {
            button {
              font-size: $font-size-mobile-feedback-header;
              max-height: 54px;
            }
          }

          @include device(xs) {
            .textbox-container {
              min-height: 30px;
              padding: 4px 8px;

              label {
                font-size: $font-mobile-tiny;
              }

              input {
                padding: 0px;
                font-size: $font-size-mobile-p;
              }
            }

            .textarea-container {
              padding: 4px 8px;

              textarea {
                font-size: $font-size-mobile-p;
              }
            }
          }
        }

        .feedback-success {
          justify-content: center;
          padding: 20px;
        }
      }
    }

    #drawer-toggle-container.toggle-drawer {
      transform: translate(0, 0);
    }
  }
}
