/**
 * Scaffolding
 */
* {
    box-sizing: border-box;
}

html {
    min-height: 100%;
}

body {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}